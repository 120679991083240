
function getAsArray(formularioExame) {

  let array = []
  const getFormulariosExame = (value) => {
    if (value.formularioExamePai) {
      const { formularioExamePai, ...obj } = value
      formularioExamePai
      array.push(obj)
      return getFormulariosExame(value.formularioExamePai)
    }
    array.push(value)
    return array
  }

  if (formularioExame) {
    return getFormulariosExame({ ...formularioExame });
  }
  return array
}

function getLastFormularioExame(formularioExame) {
  if (formularioExame?.formularioExamePai) {
    return getLastFormularioExame(formularioExame.formularioExamePai)
  }
  return formularioExame
}

export {
  getAsArray,
  getLastFormularioExame,
}