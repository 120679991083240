<template>
  <v-tooltip open-delay="800" close-delay="100" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn-toggle v-model="selected" active-class="primary white--text" dense @change="onChange" multiple>
        <v-responsive>
          <v-btn v-for="item in items" :key="item.id"
            v-bind="attrs" v-on="on"
            :value="item"
            class="mr-1"
            outlined
            small
            plain
          >
            <span>{{ item.nome || item.descricao }}</span>
          </v-btn>
        </v-responsive>
      </v-btn-toggle>
    </template>
    <span>Filtro Prioridade</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getExameFilters } from '@/utils/exameFilter'

export default {
  name: "exame-filter-buttons",

  props: {
    type: {
      type: String,
      default: "SOLICITACAO_PRIORIDADE",
      required: true,
    },
    filterSelectOff: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      selected: [],
      objectFilter: "",
    };
  },
  watch: {
    exameFilters() {
      this.loadFiltered()
    }
  },
  computed: {
    ...mapGetters(["exameFilters"]),
  },
  methods: {
    ...mapActions(["setAlerta","setExameFilters"]),
    async onChange(value) {
      if (value) {
        if (!this.filterSelectOff) {
          if (this.type == "STATUS_EXAME") {
            this.setExameFilters(await getExameFilters({...this.exameFilters, statusExame: value}))
          }
          if (this.type == "SOLICITACAO_PRIORIDADE") {
            this.setExameFilters(await getExameFilters({...this.exameFilters, solicitacaoPrioridade: value}))
          }
        }
      }
      this.$emit("return-value", value)
    },
    loadFiltered() {
      if (this.type == "STATUS_EXAME") {
        if (this.exameFilters.statusExame) {
          this.selected = this.exameFilters.statusExame;
        }else{
          this.selected.splice(0,this.selected.length)
        }
      } else if (this.type == "SOLICITACAO_PRIORIDADE") {
        if (this.exameFilters.solicitacaoPrioridade) {
          // this.selected = this.exameFilters.solicitacaoPrioridade; //Estava funcionando (verificar versão node)
          this.selected = this.items.filter(item => [...this.exameFilters.solicitacaoPrioridade].find(filter => filter.id == item.id))
        }else{
          this.selected.splice(0,this.selected.length)
        }
      }
    },
  },
};
</script>