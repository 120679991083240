<template>
  <div v-if="exame">
    <v-dialog v-model="dialog" persistent max-width="1024">
      <v-form lazy-validation v-on:submit.prevent="onSubmit" ref="form">
        <v-card flat :color="bodyColor">
          <v-card-title>
            <span class="headline primary--text">{{ titulo }}</span>
          </v-card-title>
          <v-card-text class="pb-0" v-if="!!formularioExamePergunta">
            <v-card light flat :class="`${askColor} pt-4`">
              <div class="pendencia-responsavel">
                  <span>Por: <strong>{{ formularioExamePergunta.usuarioAlteracao.nomeExibicao }}</strong> em {{ dateFormat(formularioExamePergunta.dataCadastro) }}</span>
              </div>
              <div v-html="formularioExamePergunta.formHtml"></div>
            </v-card>
          </v-card-text>
          <v-card-text :class="`${!!formularioExamePergunta ? 'pt-1' : ''}`">
            <v-card flat>
              <formulario-create-edit v-if="modeloFormulario"
                :key="modeloFormulario.id"
                :formularioParam="modeloFormulario"
                :storageNameParam="exame.solicitacao.setor.unidade.storage"
                :saveBottonOff="true"
                :autoSaveUpdate="false"
                :formularioRascunho="false"
                v-model="gravarFormulario"
                @confirmSaveForms="confirmSaveForms($event)"
              ></formulario-create-edit>
            </v-card>
          </v-card-text>
          <v-card-actions class="pr-4 pt-0 pb-4">
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="close">
              Sair
            </v-btn>
            <v-btn type="submit" color="primary">
              {{ `${formularioExamePergunta ? 'Responder' : 'Incluir Pendência'}` }}
            </v-btn>
          </v-card-actions>
          <div v-if="formulariosExame && formulariosExame.length % 2 == 0">
            <v-card-title v-if="formulariosExame.length > 0">
              <span class="headline primary--text">Pendências Anteriores</span>
            </v-card-title>
            <v-card-text justify="center" class="pb-5" v-for="idx of formulariosExame.length / 2" :key="idx">
              <v-card light>
                <v-col
                  v-for="item in [formulariosExame[(2 * idx) - 2], formulariosExame[(2 * idx) - 1]]" :key="item.id" 
                  :class="`pb-0 ${item.tipoFormulario.codigo == 'RESPOSTA_PENDENCIA' ? answerColor : askColor}`"
                >
                  <div class="pendencia-responsavel">
                    <v-col cols="auto">
                      <span>Por: <strong>{{ item.usuarioAlteracao.nomeExibicao }}</strong> em {{ dateFormat(item.dataCadastro) }}</span>
                    </v-col>
                  </div>
                  <div v-html="item.formHtml"></div>
                </v-col>
              </v-card>
            </v-card-text>
          </div>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormularioCreateEdit from '@/components/forms/FormularioCreateEdit.vue';
import { getModeloFormulariosByTipoFormularioCodigo } from '../../utils/formUtils';
import { dateFormat } from '../../utils/dateUtils';

export default {
  name: "exame-pendencia",
  props: {
    value: {
      type: Object,
      default: () => null,
    },
  },
  components: {
    FormularioCreateEdit,
  },
  data () {
    return {
      titulo: "",
      dialog: false,

      exame: null,
      formularioExamePendencia: {
        tipoFormulario: null,
        formulario: null
      },

      formulariosExame: [],
      formularioExamePergunta: null,

      modeloFormulario: null,
      gravarFormulario: false,

      bodyColor: '',
      askColor: 'purple lighten-5',
      answerColor: 'purple lighten-4'
    }
  },
  watch: {
    value(value) {
      this.exame = value;
    },
    async exame(value) {
      this.$emit("input", value);
      if (value) {
        this.titulo = `PENDÊNCIA: ${this.exame.solicitacao.paciente.nome}`
        await this.loadModel()
        this.dialog = true
      }
    },
  },
  methods: {
    ...mapActions(["setAlerta"]),
    async loadModel () {
      const self = this;

      if (self.exame.formularioExamePendencia?.id) {
        await self.$http.get(`/formularioExame/${self.exame.formularioExamePendencia.id}`)
          .then(async response => {
            self.formularioExamePendencia = response.data
      
            // Cria a lista que irá manter os registros de pendência ordenados para exibição
            if (self.formularioExamePendencia) {
              await self.setFormularioExameInList(self.formularioExamePendencia)
            }
          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao abrir pendência",
            });
          })
      }
      
      // Busca o formulário adequado ao ultimo tipo de formulario
      self.getFormsPendencia();
    },
    async isLastFormAnswer() {
      const getLastAnswer = (formularioExame) => {
        if (formularioExame?.formularioExamePai) {
          return getLastAnswer(formularioExame.formularioExamePai)
        }
        return formularioExame
      }
      
      let formExame = await getLastAnswer(this.formularioExamePendencia)
      
      if (formExame?.tipoFormulario?.codigo) {
        if (formExame.tipoFormulario.codigo === "PENDENCIA") {
          return false
        }
      }
      return true
    },
    async setFormularioExameInList(formularioExame) {

      // Busca formulário html para renderização na tela
      const formHtml = await this.$http
        .get(`/report/form/preview/${formularioExame.formulario._id}`)
        .then(response => response.data)

      // Monta a lista de formulariosExame
      this.formulariosExame.push({...formularioExame, formHtml});
      if (formularioExame.formularioExamePai) {
        return this.setFormularioExameInList(formularioExame.formularioExamePai);
      }

      //TODO Deverá ser feito um ajuste na apresentacao      
      this.formulariosExame = [...this.formulariosExame.sort((a, b) => b.dataCadastro - a.dataCadastro)]

      // Remove o item do histórico caso a última ação seja uma pendência
      if (this.formulariosExame.length > 0 && this.formulariosExame.length % 2 != 0) {
        this.formularioExamePergunta = this.formulariosExame.splice(0, 1)[0]
      } else {
        this.formularioExamePergunta = null
      }

      // Organiza o histórico de pendências em pares de pendências e respostas
      for (let i = 0; i < this.formulariosExame.length - 1; i += 2) {
        [this.formulariosExame[i], this.formulariosExame[i + 1]] = [this.formulariosExame[i + 1], this.formulariosExame[i]]
      }
      

    },
    async getFormsPendencia() {
      const CODIGO_TIPO_FORMULARIO = await this.isLastFormAnswer() ? "PENDENCIA" : "RESPOSTA_PENDENCIA"
      let modeloFormularios = await getModeloFormulariosByTipoFormularioCodigo(CODIGO_TIPO_FORMULARIO, this.exame?.solicitacao?.setor?.unidade?.id, this.exame?.tipoExame?.id)
      
      if (modeloFormularios?.length > 0) {
        
        // Ordena os modelos conforme prioridade
        modeloFormularios
          .sort((a, b) => (b.tipoExames ? 1 : 0 ) - (a.tipoExames ? 1 : 0 ))
          .sort((a, b) => (b.unidade ? 1 : 0 ) - (a.unidade ? 1 : 0 ))
        
        this.modeloFormulario = modeloFormularios[0]
      }
    },
    dateFormat(date, patternIn, patternOut) {
      if (!patternOut) patternOut = "DD/MM/YYYY HH:mm"
      return dateFormat(date, patternIn, patternOut)
    },
    onSubmit () {
      var self = this

      if(!this.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: 'error',
          textAlerta: 'Favor preencher todos os campos'
        })
      } else {
        this.gravarFormulario = true
      }
    },
    async confirmSaveForms(formulario) {

      if (formulario && formulario._id) {
        
        // Function that get the last formularioExame in chain
        let setFormularioExame = async (formularioExame, newFormularioExame) => {
          if (formularioExame?.formularioExamePai?.id) {
            setFormularioExame(formularioExame.formularioExamePai, newFormularioExame)
          } else {
            formularioExame.formularioExamePai = newFormularioExame
          } 
          return formularioExame
        }

        // Charge the new formularioExame
        const newFormularioExame = { formulario: formulario, tipoFormulario: formulario.tipoFormulario }
        if (this.formularioExamePendencia?.id) {
          await setFormularioExame(this.formularioExamePendencia, newFormularioExame)
        } else {
          this.formularioExamePendencia = { ...newFormularioExame }
        }
        this.exame.formularioExamePendencia = { ...this.formularioExamePendencia }
        this.saveUpdate()
      }
    },
    saveUpdate() {
      const self = this

      if (self.exame.id) {
        self.$http.put('/exame', self.exame)
          .then(async response => {
            self.$emit('objectReturn', await self.$http.get(`/exame/${response.data.id}`).then(response => response.data))
            
            self.setAlerta( {
              tipoAlerta : 'success',
              textAlerta : 'Pendência gerada com sucesso',
            })

            self.close()
          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta : 'error',
              textAlerta : 'Erro ao alterar exame',
            })
          })
      }
    },
    close() {
      this.exame = null
      this.dialog = false
      this.formulariosExame = []
      this.modeloFormulario = null
      this.formularioExamePergunta = null
      this.formularioExamePendencia = {
        formulario: {}
      }
    },
  },
};
</script>

<style>
.pendencia-responsavel {
  position: absolute;
  right: 16px;
  margin-top: -8px;
}
</style>