<template>
  <v-container fluid class="pb-0" v-resize="onResize">
    <v-card flat>
      <v-card-title id="exame-card-title" class="pa-0 ma-0">
        <v-tooltip open-delay="800" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div>
              <v-btn small outlined v-bind="attrs" v-on="on" text class="mr-1" @click="searchByFilters">
                <v-icon color="primary">mdi-refresh</v-icon>
              </v-btn>
            </div>
          </template>
          <span>Atualizar Lista</span>
        </v-tooltip>
        <v-tooltip open-delay="800" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn-toggle v-model="refreshList" color="primary" dense>
              <div>
                <v-btn small outlined v-bind="attrs" v-on="on" class="mr-1" :value="true">
                  <v-icon :color="refreshList ? 'primary' : 'grey'">mdi-history</v-icon>
                </v-btn>
              </div>
            </v-btn-toggle>
          </template>
          <span>{{ refreshList ? "Desativar" : "Ativar" }} atualização automática
            ( Atualiza a lista a cada 60 segundos )</span>
        </v-tooltip>
        
        <exame-filter-buttons
          :type="'SOLICITACAO_PRIORIDADE'"
          :items="solicitacaoPrioridades"
        ></exame-filter-buttons>

        <favorite-filter-buttons
          :type="'MZW2_EXAME_LIST'"
        ></favorite-filter-buttons>

        <v-spacer></v-spacer>

        <v-tooltip open-delay="800" bottom v-if="countChecked > 0 && possuiPermissao(['ROLE_ADMIN','ROLE_CLIENTE_ADMIN','ROLE_AUXILIAR_ADMIN'])">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small outlined plain text v-bind="attrs" v-on="on" @click="openDirecionamentoMedico()">
              <span>Direcionamento</span>
            </v-btn>
          </template>
          <span>Direcionamento Médico</span>
        </v-tooltip>
      </v-card-title>

      <v-list dense class="py-0">
        <v-container id="exame-list-header" fluid class="pr-10 py-0" v-if="!$vuetify.breakpoint.smAndDown">
          <v-list-item>
            <v-row dense class="font-weight-bold">
              <v-col cols="1" sm="1" md="auto">
                <v-checkbox dense hide-details class="pt-0 mt-0 pr-4" color="primary" v-model="checkAll"
                  :on-icon="countChecked != countItems ? 'mdi-checkbox-intermediate' : '$checkboxOn'"
                  :off-icon="countChecked > 0 ? 'mdi-checkbox-intermediate' : '$checkboxOff'"
                ></v-checkbox>
              </v-col>
              <v-col cols="2" sm="2" md="1">Data</v-col>
              <v-col cols="9" sm="9" md="4">Paciente</v-col>
              <v-col cols="auto" sm="auto" md="2">Tipo</v-col>
              <v-col cols="auto" sm="auto" md="1">Status</v-col>
              <v-col cols="auto" sm="auto" md="2">Unidade</v-col>
              <v-col cols="auto" sm="auto" md="1"></v-col>
            </v-row>
          </v-list-item>
          <v-progress-linear absolute :indeterminate="loading"
            :background-color="this.$vuetify.theme.dark ? `white` : `black`" background-opacity="0.1"
            color="primary"></v-progress-linear>
        </v-container>
        <v-container fluid class="overflow-auto" :style="`height: calc(100vh - ${heightScroll}px);`">
          <div v-infinite-scroll="loadMore" infinite-scroll-immediate-check="false" infinite-scroll-disabled="busy">
            <v-list-item-group v-model="selected" id="exame-table-item" multiple>
              <template v-for="(item, index) in items">
                <v-list-item :key="item.id" :class="getRowClassItem(item)" inactive selectable :ripple="false"
                  class="py-1">
                  <template>
                    <v-row dense align="center">
                      <v-col cols="1" sm="1" md="auto">
                        <v-checkbox dense hide-details @click.stop v-model="checked" multiple :value="item" class="pt-0 mt-0 pr-4"
                          color="primary"></v-checkbox>
                      </v-col>
                      <v-col cols="2" sm="2" md="1">
                        <v-tooltip open-delay="800" top>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-on="!isItToday(item.data) ? on : false" v-bind="attrs">
                              {{ getDateFormatted(item.data) }}
                            </span>
                          </template>
                          <span> {{ getDateFormattedDisplay(item.data) }} </span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="9" sm="9" md="4">
                        <v-tooltip open-delay="800" top v-if="getPrioridadeDesc(item.solicitacao.prioridade)">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon v-on="on" v-bind="attrs"
                              :color="getPrioridadeDesc(item.solicitacao.prioridade) ? getPrioridadeDesc(item.solicitacao.prioridade).color : ''"
                              class="pr-1"
                              small
                            >mdi-circle</v-icon>
                          </template>
                          <span> {{ getPrioridadeDesc(item.solicitacao.prioridade) ? getPrioridadeDesc(item.solicitacao.prioridade).descricao : '' }} </span>
                        </v-tooltip>
                        <v-tooltip open-delay="800" top v-if="(!isExameBlocked(item) || isOwnerExameBlocked(item)) && !isExameLaudoExterno(item)">
                          <template v-slot:activator="{ on, attrs }">
                            <a v-bind="attrs" v-on="on" link
                              :class="$vuetify.theme.isDark ? 'white--text' : 'black--text'" @click.stop="editar(item)">
                              {{ $_.startCase(item.solicitacao.paciente.nome.toLowerCase()) }} {{ item.numAcesso ? ` - AN: ${item.numAcesso}` : '' }}
                            </a>
                          </template>
                          <span> {{ `Id: ${item.id}` }} </span>
                        </v-tooltip>
                        <template v-else>
                          <span>{{ $_.startCase(item.solicitacao.paciente.nome.toLowerCase()) }} {{ item.numAcesso ? ` - AN: ${item.numAcesso}` : '' }}</span>
                        </template>
                      </v-col>
                      <v-col cols="auto" sm="auto" md="2">
                        <span v-if="item.tipoExame">
                          <v-chip label small dense class="pa-1">
                            {{ item.tipoExame.modalidade.sigla }}
                          </v-chip>
                          {{ item.tipoExame.nome }}
                        </span>
                        <v-btn v-else-if="possuiPermissao(['ROLE_ADMIN','ROLE_CLIENTE_ADMIN','ROLE_AUXILIAR_ADMIN'])"
                          text
                          color="primary"
                          class="text-capitalize ma-0 pa-0"
                          @click.stop="openExameTipoExame(item)"
                        >Adicionar Tipo</v-btn>
                      </v-col>
                      <v-col cols="auto" sm="auto" md="1">
                        {{ item.statusExame.descricao }}
                      </v-col>
                      <v-col cols="auto" sm="auto" md="2">
                        {{ item.solicitacao.setor.unidade.nome }}
                      </v-col>
                      <v-spacer></v-spacer>
                      <v-col cols="auto" sm="auto" md="1" class="text-end">
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" id="item-actions-botton" color="transparent" depressed x-small>
                              <v-icon large class="pb-8">mdi-settings-helper</v-icon>
                            </v-btn>
                          </template>
                          <v-list light rounded outlined elevation="2">
                            <v-list-item dense class="px-0" v-if="item.id">
                              <v-list-item-action class="ma-0" style="align-items: flex-start">
                                <v-btn :disabled="(isExameBlocked(item) && !isOwnerExameBlocked(item)) || isExameLaudoExterno(item)" small plain left
                                  class="text-capitalize" @click.prevent="editar(item)">Abrir Exame</v-btn>
                                <v-btn :disabled="(isExameBlocked(item) && !isOwnerExameBlocked(item)) || isExameLaudoExterno(item)" small plain left
                                  class="text-capitalize" @click.stop="openExameHistoriaClinica(item, index)">História Clínica</v-btn>
                                <v-btn :disabled="(isExameBlocked(item) && !isOwnerExameBlocked(item)) || isExameLaudoExterno(item)" small plain left
                                  class="text-capitalize" @click.stop="abrirReconvocacao(item, index)">Reconvocação</v-btn>
                                <v-btn :disabled="(isExameBlocked(item) && !isOwnerExameBlocked(item)) || isExameLaudoExterno(item)" small plain left
                                  class="text-capitalize" @click.stop="abrirPendencia(item, index)">Pendência de Solicitação</v-btn>
                                <v-btn :disabled="(isExameBlocked(item) && !isOwnerExameBlocked(item)) || isExameLaudoExterno(item) || ![4,5].includes(item.statusExame.id)" small plain left
                                  class="text-capitalize" @click.stop="abrirRevisao(item, index)">Revisão de Laudo</v-btn>
                                <v-btn :disabled="(isExameBlocked(item) && !isOwnerExameBlocked(item)) || isExameLaudoExterno(item)" small plain left
                                  class="text-capitalize" @click.stop="openExameMezzowResultado(item)">Mezzow Resultado</v-btn>
                                <v-btn :disabled="isExameBlocked(item) && !isOwnerExameBlocked(item)" small plain left
                                  class="text-capitalize" @click.prevent="openOutrosExames(item, index)">Outros Exames</v-btn>
                              </v-list-item-action>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                      <v-col :offset="$vuetify.breakpoint.smAndDown ? 0 : 2" :cols="$vuetify.breakpoint.smAndDown ? 12 : 10" class="pa-0 pb-1"
                        v-if="!!displayInformation(item)">
                        <v-tooltip open-delay="800" top v-if="item.dateAvailability && item.percentageLimit >= 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-on="on" v-bind="attrs" small outlined color="grey--text darken-1"
                              class="mr-2 font-weight-regular" @click.stop>
                              <v-icon :color="getClockTime(item).color" small class="mr-2">
                                {{ getClockTime(item).icon }}
                              </v-icon>
                              {{ getPercentageLimit(item) }}%
                            </v-chip>
                          </template>
                          <strong>{{ getPercentageText(item) }}</strong>
                        </v-tooltip>
                        <v-tooltip open-delay="800" top v-if="!!item.formularioExameReconvocacao">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-on="on" v-bind="attrs" small
                              color="blue lighten-5"
                              text-color="grey darken-2"
                              class="mr-2 font-weight-regular"
                              @click.stop="abrirReconvocacao(item, index)"
                            >
                              <v-icon dark small left :color="getReconvocacaoAttrs(item.formularioExameReconvocacao).iconColor">
                                {{ getReconvocacaoAttrs(item.formularioExameReconvocacao).icon }}
                              </v-icon>
                              Reconvocação
                            </v-chip>
                          </template>
                          {{ getReconvocacaoAttrs(item.formularioExameReconvocacao).tooltipText }}  
                        </v-tooltip>
                        <v-tooltip open-delay="800" top v-if="!!item.formularioExamePendencia">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-on="on" v-bind="attrs" small
                              color="purple lighten-5"
                              text-color="grey darken-2"
                              class="mr-2 font-weight-regular"
                              @click.stop="abrirPendencia(item, index)"
                            >
                              <v-icon dark small left :color="getPendenciaAttrs(item.formularioExamePendencia).iconColor">
                                {{ getPendenciaAttrs(item.formularioExamePendencia).icon }}
                              </v-icon>
                              Pendência
                            </v-chip>
                          </template>
                          {{ getPendenciaAttrs(item.formularioExamePendencia).tooltipText }}
                        </v-tooltip>
                        <v-tooltip open-delay="800" top v-if="!!item.formularioExameRevisao">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-on="on" v-bind="attrs" small
                              color="orange lighten-5"
                              text-color="grey darken-2"
                              class="mr-2 font-weight-regular"
                              @click.stop="abrirRevisao(item, index)"
                            >
                              <v-icon dark small left :color="getRevisaoAttrs(item.formularioExameRevisao).iconColor">
                                {{ getRevisaoAttrs(item.formularioExameRevisao).icon }}
                              </v-icon>
                              Revisão
                            </v-chip>
                          </template>
                          {{ getRevisaoAttrs(item.formularioExameRevisao).tooltipText }}  
                        </v-tooltip>
                        <v-tooltip open-delay="800" top v-if="item.exameDirecionados && item.exameDirecionados.length > 0">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip v-on="on" v-bind="attrs" small outlined color="grey--text darken-1"
                              class="mr-2 font-weight-regular" @click.stop="openOneDirecionamentoMedico(item)"
                            >
                              <v-icon dark small left :color="'primary'">mdi-check-circle</v-icon>
                              Dir. Médico
                            </v-chip>
                          </template>
                          <v-row v-for="exameDirecionado in item.exameDirecionados" :key="`${exameDirecionado.id.exame.id}_${exameDirecionado.id.medico.id}`" no-gutters>
                            <span>{{ exameDirecionado.id.medico.nomeExibicao }}</span>
                            <v-spacer></v-spacer>
                            <strong class="ml-2">{{ dateFormat(exameDirecionado.dataCriacao) }}</strong>
                          </v-row>
                        </v-tooltip>
                        <v-chip v-if="item.formularioExameLaudo && [4,5,6].includes(item.statusExame.id)" small outlined color="grey--text darken-1"
                          class="mr-2 font-weight-regular" @click="setAbaSidebar('laudo-exame', item)">
                          <v-icon color="red" small class="mr-2">mdi-file</v-icon> Laudo
                        </v-chip>
                        <v-chip v-if="getResultadoLink(item)" small outlined color="grey--text darken-1"
                          class="mr-2 font-weight-regular" @click.stop="openResultadoExame(item)" target="_blank">
                          <v-icon color="red" small class="mr-2">mdi-file-check</v-icon> Resultado
                        </v-chip>
                        <v-chip v-if="item.study && item.study.dataEnvioJpg" small outlined color="grey--text darken-1"
                          class="mr-2 font-weight-regular" @click="setAbaSidebar('imagens-exame', item)">
                          <v-icon color="red" small class="mr-2">mdi-folder-multiple-image</v-icon> Imagens
                        </v-chip>
                        <v-chip v-if="item.formularioExameHistoriaClinica" small outlined color="grey--text darken-1"
                          class="mr-2 font-weight-regular" @click="setAbaSidebar('historia-clinica', item)">
                          <v-icon color="red" small class="mr-2">mdi-clipboard-file</v-icon> Hitória Clínica
                        </v-chip>
                        <span class="error--text text-caption font-w" v-if="item.laudoRascunho">
                          {{ `Sendo editado por ${item.laudoRascunho.usuarioAlteracao.nomeExibicao}` }}
                        </span>

                      </v-col>
                    </v-row>
                  </template>
                </v-list-item>
                <v-divider :key="index"></v-divider>
              </template>
            </v-list-item-group>
          </div>
        </v-container>
        <v-divider></v-divider>
        <div class="font-weight-bold mt-2 d-flex justify-end">
          <span class="pr-1"> Exames listados: {{ items.length }} de </span>
          <span v-if="!loadingCount" class="pr-4"> {{ examesCount }} </span>
          <v-progress-circular v-else :size="24" class="mr-4" color="primary" indeterminate></v-progress-circular>
        </div>
      </v-list>
      <exame-reconvocacao v-model="exameReconvocacaoSelected" @objectReturn="onSaveReconvocacao"></exame-reconvocacao>
      <exame-pendencia v-model="examePendenciaSelected" @objectReturn="onSavePendencia"></exame-pendencia>
      <exame-revisao v-model="exameRevisaoSelected" @objectReturn="onSaveRevisao"></exame-revisao>
      <exame-historia-clinica-create-edit v-model="exameHistoriaClinicaSelected" @objectReturn="onSaveHistoriaClinica" />
      <exame-tipo-exame-create-edit v-model="exameTipoExameSelected" @objectReturn="onSaveTipoExame" />
      <exame-mezzow-resultado v-model="exameMezzowResultadoSelected" @objectReturn="onSaveMezzowResultado" />
      <exame-direcionamento-medico v-model="exameDirecionamentoMedicoDialog" :exames="checked" @objectReturn="onSaveDirecionamentoMedico" />
      <v-navigation-drawer right clipped app v-model="sidebar" :width="sideBarWidth" temporary>
        <exame-sidebar v-if="selected.length == 1" :key="sideBarKey" :exame="examesSelecionados[0]" :aba="abaSidebar" @close="selected=[]"></exame-sidebar>
        <div v-else>lista
          {{ examesSelecionados }}
        </div>
      </v-navigation-drawer>
      <alerta></alerta>
    </v-card>
  </v-container>
</template>

<script>
import Alerta from "@/components/comum/Alerta.vue";
import { mapActions, mapGetters } from "vuex";
import infiniteScroll from 'vue-infinite-scroll'
import axios from 'axios'
import ExameReconvocacao from './ExameReconvocacao.vue';
import { getPerfilId, isAdminAuxiliar, isAdminClient, isAdminMaster, possuiPermissao } from '../../seguranca'
import ExameSidebar from './ExameSidebar.vue';
import ExameHistoriaClinicaCreateEdit from './ExameHistoriaClinicaCreateEdit.vue';
import ExameFilterButtons from '../../components/ExameFilterButtons.vue';
import FavoriteFilterButtons from '../../components/FavoriteFilterButtons.vue';
import ExameTipoExameCreateEdit from './ExameTipoExameCreateEdit.vue';
import { getDateFormatted, getDateFormattedDisplay, isItToday, dateFormat, dateDiff } from '../../utils/dateUtils'
import { getLastFormularioExame } from '../../utils/formularioExameutils'
import ExameDirecionamentoMedico from './ExameDirecionamentoMedico.vue';
import ExamePendencia from './ExamePendencia.vue';
import ExameMezzowResultado from './ExameMezzowResultado.vue';
import ExameRevisao from './ExameRevisao.vue';

export default {
  name: "exame-list",
  data() {
    return {
      offset: 0,
      busy: false,
      heightScroll: 0,
      examesCount: 0,
      countTimeOut: null,
      loadingCount: false,
      cancelSource: null,
      refreshList: undefined,
      refreshInterval: null,
      statusFilterBottom: [],
      prioridadeFilterBottom: [],
      solicitacaoPrioridades: [
        { id: "1", descricao: "BAIXA ", color: "blue darken-1" },
        { id: "2", descricao: "MÉDIA ", color: "green darken-1" },
        { id: "3", descricao: "ALTA  ", color: "yellow darken-2" },
        { id: "4", descricao: "MÁXIMA", color: "red darken-1" },
      ],
      loading: true,
      items: [],
      titulo: "Exames",
      search: "",
      selected: [],

      checkAll: false,

      exameTipoExameSelected: null,
      exameReconvocacaoSelected: null,
      examePendenciaSelected: null,
      exameRevisaoSelected: null,
      exameHistoriaClinicaSelected: null,
      exameMezzowResultadoSelected: null,
      exameDirecionamentoMedicoDialog: false,

      sidebar: false,
      abaSidebar: 'dados-exame',
      checked: [],
    };
  },
  components: {
    Alerta,
    ExameReconvocacao,
    ExameSidebar,
    ExameHistoriaClinicaCreateEdit,
    ExameFilterButtons,
    FavoriteFilterButtons,
    ExameTipoExameCreateEdit,
    ExameDirecionamentoMedico,
    ExamePendencia,
    ExameMezzowResultado,
    ExameRevisao
  },
  directives: {
    infiniteScroll,
  },
  computed: {
    ...mapGetters([
      "exameQuery",
      "exameFilters",
      "exameLastItemId",
      "exameRefreshList",
    ]),
    countItems() {
      return this.items.length
    },
    countChecked() {
      return this.checked.length
    },
    examesSelecionados() {
      let exames = []
      this.selected.map((index) => {
        exames.push(this.items[index]);
      })
      return exames;
    },
    sideBarWidth() {
      if (this.$vuetify.breakpoint.xsOnly) return this.$vuetify.breakpoint.width
      else if (this.$vuetify.breakpoint.smOnly) return this.$vuetify.breakpoint.width
      else if (this.$vuetify.breakpoint.mdOnly) return this.$vuetify.breakpoint.width * 0.5
      else if (this.$vuetify.breakpoint.lgOnly) return this.$vuetify.breakpoint.width * 0.5
      else return this.$vuetify.breakpoint.width * 0.5
    },
    sideBarKey() {
      if (Array.isArray(this.examesSelecionados) && this.examesSelecionados.length > 0) {
        return this.examesSelecionados[0].id
      }
      return null
    },
    possuiPermissaoEditarHistoriaClinica() {
      return isAdminMaster() || isAdminClient() || isAdminAuxiliar()
    },
  },
  watch: {
    search() {
      this.setExameQuery({ query: this.search });
    },
    selected() {
      /**
       * CÓDIGO PARA PERMITIR A SELEÇÃO DE SOMENTE UM ITEM - REMOVER PARA UTILIZAR SELEÇÃO MULTIPLA
       */
      if (this.selected.length > 1) this.selected.splice(0, 1)

      this.sidebar = this.selected.length > 0
      if (this.selected.length == 0) this.abaSidebar = "dados-exame"
    },
    exameFilters() {
      this.searchByFilters();
    },
    refreshList(value) {
      if (value) {
        this.refreshInterval = setInterval(() => {
          this.searchByFilters();
        }, 60 * 1000);
      } else {
        clearInterval(this.refreshInterval);
      }
      // Altera o estado da variável exameRefreshList (vuex)
      this.setExameRefreshList(value);
    },
    checkAll(value) {
      if (value) {
        this.checked = [ ...this.items ]
      } else [
        this.checked = []
      ]
    },
    // Controla último registro acessado e se o sidebar foi fechado clicando fora da tela (remove seleção)
    sidebar(value) {
      if (value) {
        this.setExameLastItemId(this.examesSelecionados[0].id)
      } else {
        this.selected = []
      }
    }
  },
  methods: {
    ...mapActions([
      "setUltimaTela",
      "setAlerta",
      "setExameQuery",
      "setShowExameFilters",
      "setExameLastItemId",
      "setExameRefreshList",
    ]),
    onResize() {
      // TODO: Inserido atraso para a tela renderizar quando há alteração significativa
      setTimeout(() => {
        let exameHeaderElement = window.document.getElementsByTagName("header");
        let exameCardTitleElement = window.document.getElementById("exame-card-title")
        let exameListHeaderElement = window.document.getElementById("exame-list-header")
        
        this.heightScroll = 50; // Espaço no rodapé.
        if (exameHeaderElement) {
          for (let i = 0; i < exameHeaderElement.length; i++) {
            this.heightScroll += exameHeaderElement.item(0).clientHeight 
          }
        }
        if (exameCardTitleElement)
          this.heightScroll += exameCardTitleElement.clientHeight;
        if (exameListHeaderElement)
          this.heightScroll += exameListHeaderElement.clientHeight;
      }, 200);
    },
    searchByFilters() {
      this.items = []
      this.checked = []
      this.selected = []
      this.offset = 0;

      this.getExameCount()
      this.loadMore()
    },
    loadMore() {
      const self = this;
      
      let max = 50;
      let url = `/exame?offset=${self.offset}&max=${max}`
      if (this.exameFilters.urlFilter) url += this.exameFilters.urlFilter;

      // TODO: Cancelar a requisição anterior caso esteja pendente.
      if (self.cancelSource) {
        self.cancelSource.cancel();
      }
      // TODO: Realiza a nova requisição para contagem dos registros filtrados.
      self.cancelSource = axios.CancelToken.source();

      self.busy = true;
      self.loading = true;
      self.$http.get(url, {
        cancelToken: self.cancelSource.token,
      })
        .then((response) => {
          if (response.data.content) self.items = self.items.concat(response.data.content);
          self.loading = false;

          // TODO: tratamento para parar de atualizar a lista caso chegue no final
          if (response.data.numberOfElements == max) {
            self.offset++;
            self.busy = false;
          } else { 
            self.busy = true;
            self.setAlerta({
              tipoAlerta: response.data.numberOfElements == 0 ? "warning" : "success", 
              textAlerta: response.data.numberOfElements == 0 ? "Não foram encontrados exames" : "Sua lista de exames chegou ao final",
            });
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("Request cancelled: ", error);
          } else {
            self.offset = 0;
            self.busy = false;
            self.loading = false;
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao buscar exame",
            });
          }
        });
    },
    getExameCount() {
      const self = this;
      const secondsTimeout = 3 * 1000
      const countFuncition = () => { 
        let url = `/exame/count`
        if (self.exameFilters.urlFilter) url += self.exameFilters.urlFilter.replace("&", "?");
        
        self.$http.get(url)
          .then((response) => {
            self.examesCount = response.data;
            self.loadingCount = false;
          })
          .catch(() => {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Erro ao contabilizar o total de exames",
            });
          });
      }

      self.examesCount = 0;
      self.loadingCount = true;
      if (!self.countTimeOut) {
        self.countTimeOut = setTimeout(countFuncition, secondsTimeout)
      } else {
        clearTimeout(self.countTimeOut)
        self.countTimeOut = setTimeout(countFuncition, secondsTimeout)
      }
    },
    openExameFilter(value) {
      this.setShowExameFilters(value)
    },
    displayInformation(item) {
      return item.formularioExameLaudo
        || item.formularioExameHistoriaClinica
        || item.study
        || item.laudoRascunho
        || item.exameDirecionados
        || item.formularioExameReconvocacao
        || item.formularioExamePendencia
        || item.formularioExameRevisao
    },
    setAbaSidebar(aba, item) {
      this.setExameLastItemId(item.id)
      this.abaSidebar = aba;
    },
    getPrioridadeDesc(prioridade) {
      return this.solicitacaoPrioridades.find(p => p.id == prioridade)
    },
    async editar(itemParam) {
      const index = this.selected[0];
      const selecao = itemParam.id ? itemParam : this.items[index]

      // Validade Exame Permissão Médico
      const permissions = ["ROLE_ADMIN", "ROLE_MEDICO_ATENDIMENTO", "ROLE_MEDICO_RADIOLOGISTA"]
      if (!possuiPermissao(permissions)) {
        let self = this
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: `Usuário não tem permissão de acesso ao laudário!`,
        })

        // Recria o objeto selecionado na lista de itens para renderizar a lista novamente.
        self.items.splice(this.items.indexOf(selecao), 1, selecao)
        return
      }

      // Validade Exame blocked on open ExameEdit
      selecao.laudoRascunho = await this.$http.get(`laudoRascunho/exameId/${selecao.id}`)
        .then(response => response.data)
        .catch(() => null)

      if (this.isExameBlocked(selecao) && !this.isOwnerExameBlocked(selecao)) {
        let self = this
        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: `Exame esta sendo editado por: ${selecao.laudoRascunho.usuarioAlteracao.nomeExibicao}`,
        })

        // Recria o objeto selecionado na lista de itens para renderizar a lista novamente.
        self.items.splice(this.items.indexOf(selecao), 1, selecao)
        return
      }

      // Verifica se exame possui tipo exame.
      if (!selecao?.tipoExame?.id) {
        this.setAlerta({
          tipoAlerta: "error",
          textAlerta: `Tipo Exame não definido, favor entre em contato com o administrador`,
        })
        return
      }

      // Verifica se o exame foi assinado no Mezzow 1.0 (neste caso não abre)
      if ([4,5].includes(selecao.statusExame.id) && !selecao?.formularioExameLaudo?.id) {
        this.setAlerta({
          tipoAlerta: "error",
          textAlerta: `Exame ${selecao.statusExame.descricao} no Mezzow 1.0`
        })
        return
      }

      this.setExameLastItemId(selecao.id)
      this.$router.push({ name: "ExameEdit", params: { id: selecao.id } });
    },
    openOutrosExames(value, index) {
      this.selected = [index]
      this.setExameLastItemId(value.id)      
      this.setAbaSidebar("outros-exames", value)
    },
    openExameHistoriaClinica(value, index) {
      if (!value.formularioExameHistoriaClinica) {
        this.setExameLastItemId(value.id)
        if (!this.possuiPermissaoEditarHistoriaClinica) {
          this.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Usuário sem permissão criar Histórias Clínicas",
          })
          return
        }
        this.exameHistoriaClinicaSelected = value
        return
      }
      this.selected = [index]
      this.setAbaSidebar("historia-clinica", value)
    },
    onSaveHistoriaClinica(value) {
      let selected = this.items.filter(e => e.id == value.id)[0]
      this.items.splice(this.items.indexOf(selected), 1, value)
    },
    abrirReconvocacao(itemParam, index) {
      const self = this
      self.setExameLastItemId(itemParam.id)

      if (!self.possuiPermissao(['ROLE_ADMIN','ROLE_CLIENTE_ADMIN','ROLE_AUXILIAR_ADMIN','ROLE_MEDICO_RADIOLOGISTA','ROLE_MEDICO_ATENDENTE'])) {
        // Abre sideBar caso não tenha permissão de edição
        if (itemParam?.formularioExameReconvocacao?.id) {
          self.selected = [index]
          this.setAbaSidebar("reconvocacao-preview", itemParam.formularioExameReconvocacao)
          return
        }

        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Usuário sem permissão para acessar Reconvocação",
        });
        return
      }

      self.exameReconvocacaoSelected = itemParam
    },
    onSaveReconvocacao(value) {
      let selected = this.items.filter(e => e.id == value.id)[0]
      this.items.splice(this.items.indexOf(selected), 1, value)
    },
    getReconvocacaoAttrs(value) {
      const lastFormulario = getLastFormularioExame(value)
      const tooltipText = lastFormulario.tipoFormulario.codigo == 'RECONVOCACAO' ? 'Reconvocação Pendente' : `Realizada em ${this.dateFormat(lastFormulario.dataCadastro)}`
      const icon = lastFormulario?.tipoFormulario?.codigo == 'RECONVOCACAO' ? 'mdi-minus-circle' : 'mdi-check-circle'
      const iconColor = lastFormulario?.tipoFormulario?.codigo == 'RECONVOCACAO' ? 'red' : 'green'
      return {tooltipText, icon, iconColor}
    },
    abrirPendencia(itemParam, index) {
      const self = this
      self.setExameLastItemId(itemParam.id)

      if (!self.possuiPermissao(['ROLE_ADMIN','ROLE_CLIENTE_ADMIN','ROLE_AUXILIAR_ADMIN','ROLE_MEDICO_RADIOLOGISTA','ROLE_MEDICO_ATENDENTE'])) {
        // Abre sideBar caso não tenha permissão de edição
        if (itemParam?.formularioExamePendencia?.id) {
          self.selected = [index]
          this.setAbaSidebar("pendencia-preview", itemParam.formularioExamePendencia)
          return
        }

        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Usuário sem permissão para acessar Pendência",
        });
        return
      }

      self.examePendenciaSelected = itemParam
    },
    onSavePendencia(value) {
      let selected = this.items.filter(e => e.id == value.id)[0]
      this.items.splice(this.items.indexOf(selected), 1, value)
    },
    getPendenciaAttrs(value) {
      const lastFormulario = getLastFormularioExame(value)
      const tooltipText = lastFormulario.tipoFormulario.codigo == 'PENDENCIA' ? 'Pendente' : `Realizada em ${this.dateFormat(lastFormulario.dataCadastro)}`
      const icon = lastFormulario?.tipoFormulario?.codigo == 'PENDENCIA' ? 'mdi-minus-circle' : 'mdi-check-circle'
      const iconColor = lastFormulario?.tipoFormulario?.codigo == 'PENDENCIA' ? 'red' : 'green'
      return {tooltipText, icon, iconColor}
    },
    abrirRevisao(itemParam, index) {
      const self = this
      self.setExameLastItemId(itemParam.id)

      if (!self.possuiPermissao(['ROLE_ADMIN','ROLE_CLIENTE_ADMIN','ROLE_AUXILIAR_ADMIN','ROLE_MEDICO_RADIOLOGISTA','ROLE_MEDICO_ATENDENTE'])) {
        // Abre sideBar caso não tenha permissão de edição
        if (itemParam?.formularioExameRevisao?.id) {
          self.selected = [index]
          this.setAbaSidebar("revisao-preview", itemParam.formularioExameRevisao)
          return
        }

        self.setAlerta({
          tipoAlerta: "error",
          textAlerta: "Usuário sem permissão para acessar Revisão",
        });
        return
      }

      self.exameRevisaoSelected = itemParam
    },
    onSaveRevisao(value) {
      let selected = this.items.filter(e => e.id == value.id)[0]
      this.items.splice(this.items.indexOf(selected), 1, value)
    },
    getRevisaoAttrs(value) {
      const lastFormulario = getLastFormularioExame(value)
      const tooltipText = lastFormulario.tipoFormulario.codigo == 'REVISAO' ? 'Revisão de Laudo Pendente' : `Realizada em ${this.dateFormat(lastFormulario.dataCadastro)}`
      const icon = lastFormulario?.tipoFormulario?.codigo == 'REVISAO' ? 'mdi-minus-circle' : 'mdi-check-circle'
      const iconColor = lastFormulario?.tipoFormulario?.codigo == 'REVISAO' ? 'red' : 'green'
      return {tooltipText, icon, iconColor}
    },
    openExameTipoExame(value) {
      if (!value.tipoExame?.id) {
        this.setExameLastItemId(value.id)
        this.exameTipoExameSelected = value
      }
    },
    onSaveTipoExame(value) {
      let selected = this.items.filter(e => e.id == value.id)[0]
      this.items.splice(this.items.indexOf(selected), 1, value)
    },
    openExameMezzowResultado(value) {
      this.setExameLastItemId(value.id)
      this.exameMezzowResultadoSelected = value
    },
    onSaveMezzowResultado(value) {
      let selected = this.items.filter(e => e.id == value.id)[0]
      this.items.splice(this.items.indexOf(selected), 1, value)
    },
    openDirecionamentoMedico() {
      if (possuiPermissao(['ROLE_ADMIN','ROLE_CLIENTE_ADMIN','ROLE_AUXILIAR_ADMIN'])) {
        this.exameDirecionamentoMedicoDialog = !this.exameDirecionamentoMedicoDialog
        return
      }
      this.setAlerta({
        tipoAlerta: "error", 
        textAlerta: "Usuário não possui permissão para editar direcionamentos médicos!",
      })
    },
    openOneDirecionamentoMedico(item) {
      this.checked = [item]
      this.openDirecionamentoMedico()
    },
    onSaveDirecionamentoMedico(values = []) {
      if (values && values.length > 0) {
        values.forEach(value => {
          const index = this.items.findIndex(e => e.id == value.id)
          if (index > -1) {
            this.items.splice(index, 1, value)
          }
        })
      }
    },
    possuiPermissao(value) {
      return possuiPermissao(value)
    },
    statusColor(status) {
      return status == "1"
        ? "green"
        : status == "2"
        ? "primary"
        : status == "3"
        ? "red"
        : status == "4"
        ? "blue"
        : status == "5"
        ? "orange"
        : status == "6"
        ? "purple"
        : "";
    },
    isExameLastItemId(itemId) {
      return this.exameLastItemId === itemId;
    },
    isExameBlocked(exame) {
      return exame.laudoRascunho ? true : false
    },
    isOwnerExameBlocked(exame) {
      return exame.laudoRascunho?.usuarioAlteracao?.id === getPerfilId()
    },
    // isFormularioExameLaudo(exame) {
    //   return exame.formularioExameLaudo?.id ? true : false
    // },
    getRowClassItem(item) {
      let className = "row-class-item";

      // Retorna classe para exames abertos.
      if (item.statusExame.id == "1") {
        className += " row-class-item-status-aberto";
      }

      // Retorna classe para exames rascunhos.
      if (item.statusExame.id == "2") {
        className += " row-class-item-status-rascunho";
      }

      // Retorna classe para exames rascunhos.
      if (item.statusExame.id == "3") {
        className += " row-class-item-status-rascunho";
      }

      // Retorna classe para exames assinadoss.
      if (item.statusExame.id == "4") {
        className += " row-class-item-status-assinado";
      }

      // Retorna classe para exames assinadoss.
      if (item.statusExame.id == "5") {
        className += " row-class-item-status-assinado";
      }

      // Retorna classe para exames assinadoss.
      if (item.statusExame.id == "6") {
        className += " row-class-item-status-rascunho";
      }

      // Retorna classe para exames assinados caso seja relatório externo.
      if (item.statusExame.id == "7") {
        className += " row-class-item-status-laudo-externo";
      }

      // Retorna classe do último registro de exame visitado.
      if (this.isExameLastItemId(item.id)) {
        className += " row-class-item-last-visitor";
      }

      // Retorna classe de registro bloqueado
      if (this.isExameBlocked(item)) {
        className += " row-class-item-situacao-bloqueado";
      }
      return className;
    },
    getClockTime(item) {
      return item.percentageLimit <= 33 ? { color: "blue darken-1",  icon: 'mdi-clock-time-four-outline' }
        : item.percentageLimit <= 66 ? { color: "green darken-1",  icon: 'mdi-clock-time-eight-outline' }
        : item.percentageLimit < 100 ? { color: "yellow darken-2",  icon: 'mdi-clock-time-eleven-outline' }
        : { color: "red darken-1",  icon: 'mdi-clock-alert-outline' }
    },
    getPercentageLimit(item) {
      return item?.percentageLimit >= 100 ? 100 : item.percentageLimit
    },
    getPercentageText(item) {
      const formatDuration = (duration) => {
        const days = Math.floor(duration.asDays())
        const hours = duration.hours().toString().padStart(2, '0')
        const minutes = duration.minutes().toString().padStart(2, '0')
        const seconds = duration.seconds().toString().padStart(2, '0')
        
        return `${days > 0 ? days + 'd ' : ''}${hours}:${minutes}:${seconds}`;
      }
      
      if ([4,5].includes(item.statusExame.id)) {
        if (item.dateLimit >= item.formularioExameLaudo.dataPrimeiraAssinatura) {
          const tempoLaudo = dateDiff(item.formularioExameLaudo.dataPrimeiraAssinatura, item.dateLimit)
          return `Tempo para laudo: ${formatDuration(tempoLaudo)}`
        }        
        const atrasoLaudo = dateDiff(item.dateLimit, item.formularioExameLaudo.dataPrimeiraAssinatura)
        return `Atraso para laudo: ${formatDuration(atrasoLaudo)}`
      }
      return `Limite para laudo: ${this.dateFormat(item.dateLimit)}`
    },
    dateFormat(date, patternIn, patternOut) {
      if (!patternOut) patternOut = "DD/MM/YYYY HH:mm"
      return dateFormat(date, patternIn, patternOut)
    },
    isItToday(date) {
      return isItToday(date)
    },
    getDateFormatted(date) {
      return getDateFormatted(date)
    },
    getDateFormattedDisplay(date) {
      return getDateFormattedDisplay(date)
    },
    getResultadoLink(item) {
      if (Array.isArray(item.resultados)) {
        let resultadoExame = [...item.resultados].sort((a, b) => b.id - a.id).find(a => a.idResultado)
        if (resultadoExame?.idResultado) {
          return `${process.env.VUE_APP_MEZZOW_RESULTADO_URL}/#resultado/${resultadoExame.idResultado}`
        }
      }
    },
    openResultadoExame(item) {
      const link = document.createElement('a');
      link.href = this.getResultadoLink(item);
      link.target = `resultado_${item.id}`
      link.click()
      
      this.setExameLastItemId(item.id)
    },
    isExameLaudoExterno(item) {
      // Retorna se o exame é LAUDO_EXTERNO
      return item?.statusExame?.id == 7
    },
  },
  created() {
    this.setUltimaTela({ tela: this.$route.fullPath });
    this.search = this.exameQuery;

    // Carrega a variável a partir do atributo de estado do vuex (computed)
    this.refreshList = this.exameRefreshList;
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
  },
  destroyed() {
    // Delete notificacion to open filter
    this.openExameFilter(false);
  },
  mounted() {
    this.onResize();
    
    // Create a new notificacion to open filter
    this.openExameFilter(true);
  }
};
</script>

<style scoped>
/* CSS para controle da exibição dos itens do v-data-table com id:  exame-table-item */
::v-deep #exame-list-header ,
::v-deep #exame-table-item {
  font-size: 14px;
}
::v-deep #exame-table-item .row-class-item, ::before {
  position: relative;
}
::v-deep #exame-table-item .row-class-item:hover {
  box-shadow: inset 1px 0px 0px #dadce0, inset -1px 0px 0px #dadce0,
    0px 1px 2px 0px rgba(60, 64, 67, 30%), 0px 1px 3px 1px rgba(60, 64, 67, 15%) !important;
  z-index: 2;
  background-color: inherit;
}
::v-deep .theme--dark #exame-table-item .row-class-item:hover {
  box-shadow: inset 1px 0px 0px rgb(255, 255, 255, 20%),
    inset -1px 0px 0px rgba(255, 255, 255, 20%),
    0px 0px 4px 0px rgba(95, 99, 104, 60%),
    0px 0px 6px 2px rgba(95, 99, 104, 60%) !important;
  z-index: 2;
  background-color: inherit;
}
::v-deep #exame-table-item .row-class-item a:hover {
  text-decoration: underline;
}
::v-deep #exame-table-item .row-class-item .mdi-checkbox-blank-outline {
  opacity: 0.3;
}
::v-deep #exame-table-item .row-class-item:hover .mdi-checkbox-blank-outline {
  opacity: 1;
}
::v-deep #exame-table-item .row-class-item .icon-historia-clinica {
  opacity: 0.3;
}
::v-deep #exame-table-item .row-class-item:hover .icon-historia-clinica {
  opacity: 1;
}
::v-deep #exame-table-item .row-class-item #item-actions-botton {
  opacity: 0.3;
}
::v-deep #exame-table-item .row-class-item:hover #item-actions-botton {
  opacity: 1;
}
::v-deep #exame-table-item .row-class-item-status-aberto {
  font-weight: bold;
}
::v-deep #exame-table-item .row-class-item-status-assinado {
  background-color: rgba(242, 245, 245, 0.8) !important;
}
::v-deep .theme--dark #exame-table-item .row-class-item-status-assinado {
  background-color: rgba(242, 245, 245, 0.2) !important;
}
::v-deep #exame-table-item .row-class-item-status-rascunho {
  font-weight: 300;
}
::v-deep #exame-table-item .row-class-item-last-visitor {
  box-shadow: inset 0px 0px 0px 2px rgba(112, 191, 115, 0.8);
}
::v-deep #exame-table-item .row-class-item-situacao-bloqueado {
  background-color: rgba(243, 105, 105, 0.2) !important;
}
::v-deep #exame-table-item .row-class-item-status-laudo-externo {
  background-color: rgba(219, 223, 221, 0.8) !important;
}
::v-deep .theme--dark #exame-table-item .row-class-item-status-laudo-externo {
  background-color: rgba(186, 187, 187, 0.2) !important;
}
</style>
