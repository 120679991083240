<template>
  <div v-if="exameShow">
    <v-dialog v-model="dialog" persistent max-width="960">
      <v-form lazy-validation v-on:submit.prevent="onSubmit" ref="form">
        <v-card outlined>
          <v-card-title v-if="!titleOff">
            <span class="headline primary--text">Adicionar Tipo Exame</span>
          </v-card-title>
          <v-card-text v-if="!headersOff">
            <v-row dense>
              <v-col cols="12" md="2">
                <v-tooltip open-delay="800" top>
                  <template v-slot:activator="{ on, attrs }">
                    <span
                      v-on="!isItToday(exameShow.data) ? on : false"
                      v-bind="attrs"
                    >{{ getDateFormatted(exameShow.data) }}</span>
                  </template>
                  <span> {{ getDateFormattedDisplay(exameShow.data) }} </span>
                </v-tooltip>
              </v-col>
              <v-col cols="12" md="4">{{ $_.startCase(exameShow.solicitacao.paciente.nome.toLowerCase()) }}</v-col>
              <v-col cols="12" md="4">{{ exameShow.solicitacao.setor.unidade.nome }}</v-col>
              <v-col cols="12" md="2" class="text-center">
                <v-chip dark small label style="width: 125px" class="justify-center"
                  :color="statusColor(`${exameShow.statusExame.id}`) + ' white--text'"
                >
                  <span>{{ exameShow.statusExame.descricao }}</span>
                </v-chip>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row dense class="mr-1">
              <v-col>
                <v-autocomplete
                  rounded
                  filled
                  dense
                  :label="'Tipo Exame'"
                  :items="items"
                  item-value="id"
                  :item-text="'descricao'"
                  v-model="exameShow.tipoExame"
                  return-object
                  :rules="[rules.campoObrigatorio]"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="exameShow.solicitacao.historiaClinica">
            <span>{{ exameShow.solicitacao.historiaClinica }}</span>
          </v-card-text>
          <v-card-actions class="pr-4">
            <v-spacer></v-spacer>
            <v-btn v-if="possuiPermissao(['ROLE_ADMIN','ROLE_CLIENTE_ADMIN','ROLE_AUXILIAR_ADMIN'])"
              color="success" class="text-capitalize" outlined :href="webAdminUrl" target="_blank"
            >Editar Solicitação</v-btn>
            <v-btn color="primary" class="text-capitalize" outlined type="submit">Adicionar</v-btn>
            <v-btn color="error" class="text-capitalize" outlined v-if="!cancelBottonOff" @click="close">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { possuiPermissao } from '../../seguranca';
import { getDateFormatted, getDateFormattedDisplay, isItToday } from '../../utils/dateUtils';

export default {
  name: "exame-tipo-exame-create-edit",
  props: {
    value: {
      type: Object,
      default: () => null,
    },
    titleOff: {
      type: Boolean,
      default: false,
    },
    headersOff: {
      type: Boolean,
      default: false,
    },
    cancelBottonOff: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialog: false,
      exame: null,
      exameShow: null,
      items: [],

      rules: {
        campoObrigatorio: (v) => !!v || "Campo é obrigatório",
        textoObrigatorio: (v) => (v && !!v.trim()) || "Valor não pode ser branco",
        emailObrigatorio: (v) => /.+@.+\..+/.test(v) || "E-mail inválido",
        selecaoObrigatoria: (v) => v.length > 0 || "Seleção Obrigatória",
      },
    }
  },
  watch: {
    value(value) {
      this.exame = value;
    },
    exame(value) {
      this.$emit("input", value);
      if (value) {
        // Busca tipos de exame quando o componente é chamado
        this.buscarTipoExames()
        // Busca o exame completo para consistir dados na persistência
        this.getExameFull()
        this.dialog = true
      }
    },
  },
  computed: {
    webAdminUrl() {
      return `${process.env.VUE_APP_MEZZOW_WEB_ADMIN_URL}solicitacao/edit/${this.exame.solicitacao.id}`;
    },
  },
  methods: {
    ...mapActions(["setAlerta"]),
    isItToday(date) {
      return isItToday(date)
    },
    getDateFormatted(date) {
      return getDateFormatted(date)
    },
    getDateFormattedDisplay(date) {
      return getDateFormattedDisplay(date)
    },
    statusColor(status) {
      return status == "1"
        ? "green"
        : status == "2"
        ? "primary"
        : status == "3"
        ? "red"
        : status == "4"
        ? "blue"
        : status == "5"
        ? "orange"
        : "";
    },
    async getExameFull() {
      const self = this
      await self.$http.get(`/exame/${this.exame.id}`)
        .then(response => {
          self.exameShow = response.data
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Não foi possível buscar o exame!",
          })
        })
    },
    buscarTipoExames() {
      const self = this;
      self.$http
        .get(`/tipoExame?max=-1`)
        .then((response) => {
          self.items = response.data.content;
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Tipo Exames",
          });
        });
    },
    saveUpdate() {
      const self = this

      if (self.exameShow.id) {
        self.$http.put('/exame', self.exameShow)
          .then(async response => {
            self.$emit('objectReturn', await self.$http.get(`/exame/${response.data.id}`).then(response => response.data))
            self.setAlerta( {
              tipoAlerta : 'success',
              textAlerta : 'Tipo Exame incluído com sucesso',
            })
            this.close();
          })
          .catch(async () => {
            self.setAlerta({
              tipoAlerta : 'error',
              textAlerta : 'Erro ao alterar exame',
            })
          })
      }
    },
    close() {
      this.exame = null
      this.exameShow = null
      this.dialog = false
    },
    onSubmit() {
      const self = this
      
      if(!self.$refs.form.validate()) {
        self.setAlerta({
          tipoAlerta: 'error',
          textAlerta: 'Favor preencher todos os campos'
        })
        return
      }

      self.saveUpdate();
    },
    possuiPermissao(value) {
      return possuiPermissao(value)
    },
  },
};
</script>