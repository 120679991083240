<template>
  <v-tooltip open-delay="800" close-delay="100" bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn-toggle v-model="selected" active-class="primary white--text" dense @change="onChange">
        <v-responsive>
          <v-btn v-for="item in items" :key="item.id"
            v-bind="attrs" v-on="on"
            :value="item"
            class="mr-1"
            outlined
            small
            plain
          >
            <span>{{ item.nome }}</span>
          </v-btn>
        </v-responsive>
      </v-btn-toggle>
    </template>
    <span>Filtro Favorito</span>
  </v-tooltip>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getExameFilters } from '@/utils/exameFilter'

export default {
  name: "favorite-filter-buttons",

  props: {
    type: {
      type: String,
      default: "MZW2_EXAME_LIST",
      required: true,
    },
    filterSelectOff: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      items: [],
      selected: null,
      modelFilters: null,
    };
  },
  watch: {
    updateFavoriteModel(value) {
      if (value) {
        this.getFavoriteFilters()
      }
    },
    exameFilters() {
      this.loadFavoriteFiltered()
    }
  },
  computed: {
    ...mapGetters(["exameFilters","updateFavoriteModel"]),
    defaultFilters() {
      return {
        periodo: null,
        pacienteQuery: null,
      };
    },
  },
  methods: {
    ...mapActions(["setAlerta","setExameFilters"]),
    getFavoriteFilters() {
      const self = this;
      self.$http
        .get(`/favorito`)
        .then((response) => {
          self.items = response.data.filter(f => f.situacao === true && f.tipo === this.type);
          self.loadFavoriteFiltered()
        })
        .catch(() => {
          self.setAlerta({
            tipoAlerta: "error",
            textAlerta: "Erro ao buscar Favoritos",
          });
        });
    },
    onChange(value) {
      if (value && value.valor) {
        if (!this.filterSelectOff) {
          this.modelFilters = JSON.parse(value.valor)

          if (this.type == "MZW2_EXAME_LIST") {
            this.setExameFilters(this.modelFilters)
          } else {
            self.setAlerta({
              tipoAlerta: "error",
              textAlerta: "Tipo de filtro não encontrado",
            });
          }
        }
      } else {
        this.cleanFilters()
      }
      this.$emit("return-value", value)
    },
    loadFavoriteFiltered() {
      this.selected = this.items.find(item => JSON.parse(item.valor)?.urlFilter == this.exameFilters?.urlFilter)
    },
    async cleanFilters() {
      let exameFilters = JSON.parse(JSON.stringify(this.exameFilters));

      for (const property in exameFilters) {
        if (property === "textFilter" || property === "urlFilter") {
          exameFilters[property] = "";
        } else if (property === "showFilters") {
          exameFilters[property] = true;
        } else {
          exameFilters[property] = null;
        }
      }
      exameFilters = { ...exameFilters, ...this.defaultFilters };
      this.setExameFilters(await getExameFilters(exameFilters))
    },
  },
  created() {
    this.getFavoriteFilters()
  },
};
</script>